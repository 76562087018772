import React from "react";
import { ButtonProps } from "xt-design";
import classNames from "classnames";
import { useTranslation, useUtmTracker } from "hooks";
import { useUserAgentState } from "context";
import styles from "./index.module.scss";
import { LOGIN, REGISTER } from "constants/router";
import { setXtLng } from "utils";
import { useRouter } from "next/router";
import useSetting from "hooks/useSetting";
import { commonADTrack } from "utils/traceUtil";

interface IProps extends ButtonProps {
  loginText?: string | null;
  registerText?: string | null;
  beforeClickLogin?: () => void;
  beforeClickRegister?: () => void;
  mobile?: boolean;
}

interface IButtonProps {
  children: React.ReactNode;
  [key: string]: any;
}
const Button: React.FC<IButtonProps> = ({ children, ...props }) => {
  return (
    <div className={styles.button} {...props}>
      {children}
    </div>
  );
};

const LoginMenu = (props: IProps) => {
  const { locale } = useRouter();
  const { t } = useTranslation();
  const { utmSearchSource } = useUtmTracker();

  const handleClickLogin = () => {
    if (window?.location) {
      setXtLng(locale || "zh-HK");
      location.href = `${LOGIN}?${utmSearchSource}`;
    }
  };

  const handleClickRegister = () => {
    if (window?.location) {
      setXtLng(locale || "zh-HK");
      // tiktok 数据回传
      window?.ttq?.track?.("CreateClick", { value: "0.5", currency: "USD" });
      commonADTrack({ name: "Create_Account" });
      location.href = `${REGISTER}?${utmSearchSource}`;
    }
  };

  return (
    <div className={classNames(styles.menu)}>
      <Button onClick={handleClickLogin} className={classNames(styles.button, styles.login)}>
        {t("new-common-login")}
      </Button>
      <Button onClick={handleClickRegister} className={classNames(styles.button, styles.register)}>
        {t("new-common-register")}
      </Button>
    </div>
  );
};

export default LoginMenu;
