import React, { useRef } from "react";
import { useRouter } from "next/router";
import { Dropdown, Menu } from "xt-design";
import classNames from "classnames";
import { CusLink } from "components";
import ContactUSButton from "components/CusButton";
import ChangeLanguage from "components/ChangeLanguage";
import NewLoginMenu from "components/NewLoginMenu";
import { useCommonState } from "context";
import XTLogo from "components/XTLogo";
import { sensorTrack } from "utils/track/sensorUtils";
import { INTERNATIONAL_SUPPORT_CONTACT_US } from "constants/router";
import { MenuItemProps } from "types/language";
import styles from "./index.module.scss";
import type { HeaderProps } from "../types";
import { CusBtnSourceEnum } from "../../constants/enum";

const Header: React.FC<HeaderProps & { className?: string }> = (props) => {
  const { menus, className, children } = props;
  const { pathname } = useRouter();
  const { isGlobal, isLanding } = useCommonState();
  const placeRef = useRef<HTMLDivElement>(null);

  const labelNode = (i: MenuItemProps) => <li key={i.label}>{i.label}</li>;

  const renderLink = (i: MenuItemProps) => {
    if (i.children?.length) {
      const Menus = (
        <Menu
          selectable={true}
          forceSubMenuRender
          items={i.children.map((i) => ({
            label: (
              <CusLink key={i.value} href={i.value} onClick={i?.onClick ? (e) => i?.onClick?.(e) : () => {}}>
                {i.label}
              </CusLink>
            ),
            key: i.value,
          }))}
        />
      );
      return (
        <Dropdown key={i.label} getPopupContainer={(triggerNode: HTMLElement) => triggerNode} overlay={Menus} placement={"bottom"}>
          {labelNode(i)}
        </Dropdown>
      );
    }
    return labelNode(i);
  };

  const renderRight = () => {
    if (pathname === INTERNATIONAL_SUPPORT_CONTACT_US) return null;
    if (isGlobal) return <NewLoginMenu />;

    return (
      <ContactUSButton
        className={styles.contactUSButton}
        showIcon={false}
        source={isLanding ? CusBtnSourceEnum.HOME_LANDING : ""}
        style={{ margin: 0 }}
        beforeClick={() => {
          sensorTrack("hongkongsite_click", { hk_official_url: window.location.href, hk_official_button_name: "联络我们" });
        }}
      />
    );
  };

  return (
    <header className={classNames(styles.headerWrapper, className)} id={"international-header"} ref={placeRef}>
      {children}
      <div className={classNames("xt-safe-row", styles.header)}>
        <XTLogo className={styles.logo} />
        <div className={classNames(styles.menusWrapper)}>
          <ul className={classNames(styles.menus)} id={"international-header-menu"}>
            {menus.map((i) => renderLink(i))}
          </ul>
          {!isLanding && (
            <div className={styles.language}>
              <ChangeLanguage newHeader />
            </div>
          )}
        </div>
        {renderRight()}
      </div>
    </header>
  );
};

export default Header;
