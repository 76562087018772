import React from "react";
import { Button } from "components/Button";
import classNames from "classnames";
import { useTranslation } from "hooks";
import styles from "./index.module.scss";
import { LOGIN, REGISTER } from "constants/router";
import { setXtLng } from "utils";
import { useRouter } from "next/router";
import { commonADTrack } from "utils/traceUtil";

interface IProps {
  loginText?: string | null;
  registerText?: string | null;
  beforeClickLogin?: () => void;
  beforeClickRegister?: () => void;
}

const NewLoginMenu = (props: IProps) => {
  const { loginText, registerText, beforeClickLogin, beforeClickRegister, ...rest } = props;
  const { locale } = useRouter();
  const { t } = useTranslation();
  const regex = /&?lng=[^&]*(&|$)/;

  const handleClickLogin = () => {
    beforeClickLogin?.();
    if (window?.location) {
      setXtLng(locale || "zh-HK");
      location.href = `${LOGIN}${window.location.search?.replace(regex, "$1")}`;
    }
  };

  const handleClickRegister = () => {
    beforeClickRegister?.();
    if (window?.location) {
      setXtLng(locale || "zh-HK");
      // tiktok 数据回传
      window?.ttq?.track?.("CreateClick", { value: "0.5", currency: "USD" });
      commonADTrack({ name: "Create_Account" });
      location.href = `${REGISTER}${window.location.search?.replace(regex, "$1")}`;
    }
  };

  return (
    <div className={classNames(styles.menu)}>
      <Button onClick={handleClickLogin} className={classNames(styles.button, styles.login)} {...rest}>
        {t("new-common-login")}
      </Button>
      <Button onClick={handleClickRegister} className={classNames(styles.button, styles.register)} {...rest}>
        {t("new-common-register")}
      </Button>
    </div>
  );
};

export default NewLoginMenu;
