import React, { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "hooks";
import { CusIcon, OutLink } from "components";
import { FooterProps } from "../types";
import { MenuItemProps } from "types/language";
import { INTERNATIONAL_LOGO_FOOTER_MOBILE } from "constants/images";
import styles from "./index.module.scss";
import IconArrowDown from "components/SvgComponents/arrow-down.svg";
import IconArrowUp from "components/SvgComponents/arrow-up.svg";

const NewFooter = ({ menus, policies, copyright, email, links, className }: FooterProps & { className?: string }) => {
  const { t } = useTranslation();
  const [currentActiveIndex, setCurrentActiveIndex] = useState<number | null>(null);

  const node = (i: MenuItemProps) => (
    <OutLink href={i.value} className={styles.text} rel={i.rel} key={i.label} onClick={i.onClick} disabledLocaleAdd={i.disabledLocaleAdd} disabledLanding={i.disabledLanding} target={i.target}>
      {i.label}
    </OutLink>
  );

  const _renderRouteLinks = (menu: MenuItemProps, index: number) => {
    const isActive = currentActiveIndex === index;

    return (
      <ul className={classNames(styles.menuItem)} key={index}>
        <li
          className={classNames(styles.title)}
          key={menu.label}
          onClick={() => {
            if (isActive) {
              setCurrentActiveIndex(null);
              return;
            }
            setCurrentActiveIndex(index);
          }}
        >
          <span>{menu.label}</span>
          {isActive ? <IconArrowUp className={styles.arrowIcon} /> : <IconArrowDown className={styles.arrowIcon} />}
        </li>
        <ul className={classNames(styles.subItems, isActive && styles.itemSelected)}>
          {menu.children?.map((i) => (
            <li className={classNames(styles.item)} key={i.label}>
              {node(i)}
            </li>
          ))}
        </ul>
      </ul>
    );
  };

  return (
    <footer className={classNames(styles.footer, className)} id={"international-footer"}>
      <section className={classNames("xt-safe-row", styles.navs)}>
        <div className={styles.menus}>{menus.map(_renderRouteLinks)}</div>
        <div className={styles.contact}>
          <div className={styles.email}>{email}</div>
          <div className={styles.social}>
            <div className={styles.title}>{t("common-followUs")}:</div>
            <ul className={styles.socialMedias}>
              {links.map((link, index) => {
                const { title, href, icon } = link;
                return (
                  <li key={index} className={styles.socialLink}>
                    <a href={href} title={title} target={"_blank"} rel={"noopener noreferrer nofollow"}>
                      <CusIcon type={icon} />
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </section>
      <section className={classNames("xt-safe-row", styles.policy)}>
        <img alt="logo" src={INTERNATIONAL_LOGO_FOOTER_MOBILE} className={styles.logo} />
        <ul className={styles.policiesWrapper}>
          {policies.map((policy, index) => {
            return (
              <li key={index} className={styles.policyItem}>
                {node(policy)}
              </li>
            );
          })}
        </ul>
      </section>
      <section className={classNames("xt-safe-row", styles.copyrightWrapper)}>
        <div className={styles.divider} />
        <div className={styles.copyright}>{copyright}</div>
      </section>
    </footer>
  );
};

export default NewFooter;
