import React, { useState } from "react";
import debounce from "lodash/debounce";
import IconSquareLogo from "components/SvgComponents/square_logo.svg";
import Button from "components/Button";
import { callApp, Constants } from "xt-web-core";
import { useRouter } from "next/router";
import styles from "./index.module.scss";

const AppGuideBanner = () => {
  const [calling, setCalling] = useState(false);
  const router = useRouter();
  const { query } = router;
  const { utm_source = "" } = query;

  const handleCallApp = debounce(() => {
    setCalling(true);
    callApp({
      appInfo: Constants.XTAppInfo,
      fallbackUrl: `/app`,
      utmSource: utm_source as string,
      onFailed: () => {
        setCalling(false);
      },
      onSuccess: () => {
        setCalling(false);
      },
    });
  }, 500);

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <IconSquareLogo className={styles.squareLogo} />
        <span className={styles.desc}>Use XTransfer anytime, anywhere</span>
      </div>
      <Button className={styles.guideBtn} type="primary" onClick={handleCallApp} suffix={calling ? <span className={styles.loading} /> : null}>
        GET APP
      </Button>
    </div>
  );
};

export default AppGuideBanner;
