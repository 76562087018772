/* eslint-disable complexity */
import { useRouter } from "next/router";
import React, { ReactElement, useCallback, useEffect, useMemo } from "react";
import { usePlatform, useTranslation } from "hooks";
import { useScroll } from "ahooks";
import { isBrowser, sensorTrack } from "utils";
import { getPathWithSite } from "utils/route";
import CookieBanner from "components/CookieBanner";
import IfElse from "components/IfElse";
import Main from "./Main";
import Footer from "./Footer";
import Header from "./Header";
import { ComponentCommonProps } from "types/reactExtra";
import HeaderMobile from "./HeaderMobile";
import FooterMobile from "./FooterMobile";
import { useCommonState } from "context";
import {
  ACCOUNT_TERMS_OF_USE_POLICY,
  COOKIE_POLICY,
  EXCHANGE_SERVICE_AGREEMENT_POLICY,
  HOME,
  INTERNATIONAL_ABOUT_US_CAREER,
  INTERNATIONAL_ABOUT_US_COMPANY,
  INTERNATIONAL_ABOUT_US_NEWS_AND_BLOG,
  INTERNATIONAL_ABOUT_US_PARTNER,
  INTERNATIONAL_ARTICLES,
  INTERNATIONAL_BUSINESS_WALLET,
  INTERNATIONAL_COOKIE_POLICY,
  INTERNATIONAL_PRIVACY_POLICY,
  INTERNATIONAL_SERVICE_COLLECTION,
  INTERNATIONAL_SERVICE_EXCHANGE,
  INTERNATIONAL_SERVICE_PAYMENT,
  INTERNATIONAL_SERVICE_PRICING,
  INTERNATIONAL_SUPPORT_CONTACT_US,
  INTERNATIONAL_WEBSITE_TERMS,
  PRIVATE_POLICY,
  RESOURCE_COMMUNITY,
  RESOURCE_EXCHANGE_RATE,
  RESOURCE_HS_CODE,
  RESOURCE_SWIFT_CODE,
  USER_AGREEMENT_POLICY,
} from "constants/router";
import { SitesEnum } from "constants/regions";
import { MenuItemProps } from "types/language";
import type { FooterProps, HeaderProps } from "./types";
import AdBanner from "components/AdBanner";
import AppGuideBanner from "./components/AppGuideBanner";

interface LayoutProps extends Omit<ComponentCommonProps, "style" | "className"> {
  title?: string;
  disabledAppGuideBanner?: Boolean;
}

const NewLayout = ({ children, disabledAppGuideBanner }: LayoutProps) => {
  const { isLanding, isMobile, host, isGlobal } = useCommonState();
  const { inApp } = usePlatform();
  const { t } = useTranslation();
  const { query } = useRouter();
  const { site } = query as { site: SitesEnum };

  // TODO 这个在滚动时会导致整个header一直刷新，需要抽离到单独的组件，缩小刷新范围
  const scroll = useScroll(isBrowser ? document : undefined);

  const doHelpTrace = () => {
    sensorTrack("FUND_click", { button_name: "Global-bar-helpcenter" });
  };

  const navs: MenuItemProps[] = [
    {
      label: t("common-products-services"),
      value: "",
      children: [
        { label: t("common-business-wallet"), value: getPathWithSite(site, INTERNATIONAL_BUSINESS_WALLET) },
        { label: t("common-add-receive-money"), value: getPathWithSite(site, INTERNATIONAL_SERVICE_COLLECTION) },
        { label: t("common-currency-send-money"), value: getPathWithSite(site, INTERNATIONAL_SERVICE_PAYMENT) },
        { label: t("common-currency-exchange"), value: getPathWithSite(site, INTERNATIONAL_SERVICE_EXCHANGE) },
        { label: t("common-pricing"), value: getPathWithSite(site, INTERNATIONAL_SERVICE_PRICING) },
      ],
    },
    {
      label: t("common-about-us"),
      value: "",
      children: !isGlobal
        ? [
            { label: t("common-company"), value: getPathWithSite(site, INTERNATIONAL_ABOUT_US_COMPANY) },
            { label: t("common-career"), value: getPathWithSite(site, INTERNATIONAL_ABOUT_US_CAREER) },
            { label: t("common-partner"), value: getPathWithSite(site, INTERNATIONAL_ABOUT_US_PARTNER) },
          ]
        : [
            { label: t("common-company"), value: getPathWithSite(site, INTERNATIONAL_ABOUT_US_COMPANY) },
            { label: t("common-news-and-blog"), value: getPathWithSite(site, INTERNATIONAL_ABOUT_US_NEWS_AND_BLOG) },
            { label: t("common-career"), value: getPathWithSite(site, INTERNATIONAL_ABOUT_US_CAREER) },
            { label: t("common-partner"), value: getPathWithSite(site, INTERNATIONAL_ABOUT_US_PARTNER) },
          ],
    },
    {
      label: t("common-help-support"),
      value: "",
      children: !isGlobal
        ? [{ label: t("common-contact-us"), value: getPathWithSite(site, INTERNATIONAL_SUPPORT_CONTACT_US) }]
        : [
            { label: t("common-faqs"), value: HOME, onClick: doHelpTrace },
            { label: t("common-contact-us"), value: getPathWithSite(site, INTERNATIONAL_SUPPORT_CONTACT_US) },
          ],
    },
  ];

  const headerData: HeaderProps = { menus: isLanding ? [] : navs };

  const policies: MenuItemProps[] =
    !isGlobal && !isLanding
      ? [
          // {
          //   label: t("common-securityPolicy"),
          //   value: getPathWithSite(site, INTERNATIONAL_PRIVACY_POLICY),
          //   rel: "noopener noreferrer nofollow",
          // },
          // {
          //   label: t("common-cookiePolicy"),
          //   value: getPathWithSite(site, INTERNATIONAL_COOKIE_POLICY),
          //   rel: "noopener noreferrer nofollow",
          // },
          // {
          //   label: t("common-websiteTerms"),
          //   value: getPathWithSite(site, INTERNATIONAL_WEBSITE_TERMS),
          //   rel: "noopener noreferrer nofollow",
          // },
        ]
      : [
          {
            label: t("XTS-31051.pages.common-user-agreement"),
            value: getPathWithSite(site, USER_AGREEMENT_POLICY),
            rel: "noopener noreferrer nofollow",
            target: "_blank",
          },
          {
            label: t("XTS-31051.pages.common-private-policy"),
            value: getPathWithSite(site, PRIVATE_POLICY),
            rel: "noopener noreferrer nofollow",
            target: "_blank",
          },
          {
            label: t("XTS-31051.pages.common-cookie-policy"),
            value: getPathWithSite(site, COOKIE_POLICY),
            rel: "noopener noreferrer nofollow",
            target: "_blank",
          },
          {
            label: t("XTS-31051.pages.common-exchange-service-agreement"),
            value: getPathWithSite(site, EXCHANGE_SERVICE_AGREEMENT_POLICY),
            rel: "noopener noreferrer nofollow",
            target: "_blank",
          },
          {
            label: t("XTS-31051.pages.common-account-terms-of-use"),
            value: getPathWithSite(site, ACCOUNT_TERMS_OF_USE_POLICY),
            rel: "noopener noreferrer nofollow",
            target: "_blank",
          },
        ];

  const footerData: FooterProps = {
    menus: [...navs].concat(
      !isGlobal
        ? []
        : [
            {
              label: "Resources",
              value: `javascript:;`,
              children: [
                { label: t("common-knowledge-hub"), value: INTERNATIONAL_ARTICLES },
                { label: t("common-hs-code"), value: RESOURCE_HS_CODE },
                { label: t("common-exchange-rate"), value: `https://${host}${RESOURCE_EXCHANGE_RATE}` },
                { label: t("common-community"), value: RESOURCE_COMMUNITY },
                { label: t("common-swift-code"), value: RESOURCE_SWIFT_CODE },
              ],
            },
          ]
    ),
    policies,
    copyright: t("common-copyright-2024"),
    email: t("common-email"),
    links: [
      {
        href: t("new-common-FaceBook-link"),
        title: "FaceBook",
        icon: "xtFacebook",
      },
      {
        href: t("common-Linkedin"),
        title: "LinkedIn",
        icon: "xtLinkedin",
      },
      {
        href: t("common-Instagram"),
        title: "Instagram",
        icon: "xtinstagram",
      },
      {
        href: t("common-Youtube"),
        title: "Youtube",
        icon: "xtyoutube",
      },
      {
        href: t("new-common-Twitter-link"),
        title: "Twitter",
        icon: "xttuitetwitter",
      },
    ],
  };

  const changeHeaderShadow = useCallback((add?: boolean) => {
    const header = document.getElementById("international-header");
    if (header) add ? header.classList.add("xt-new-shadow") : header.classList.remove("xt-new-shadow");
  }, []);

  useEffect(() => {
    if (scroll?.top === 0) {
    }
    changeHeaderShadow((scroll?.top || 0) > 0);
  }, [changeHeaderShadow, scroll?.top]);

  // 香港展跳转到国际站时，第三方组件会在body上添加overflow: hidden，这里需要手动清除
  useEffect(() => {
    document.body.removeAttribute("style");
  }, []);

  const style = useMemo(() => {
    if (!disabledAppGuideBanner && isMobile && !isLanding) {
      return {
        paddingBottom: "13.8667vw",
      };
    }
  }, [isMobile, isLanding]);

  return (
    <div style={style}>
      <IfElse
        if={!!isMobile}
        else={
          <Header {...headerData}>
            <AdBanner />
          </Header>
        }
      >
        <HeaderMobile {...headerData}>
          <AdBanner />
        </HeaderMobile>
      </IfElse>
      <Main>{children}</Main>
      <IfElse if={!!isMobile} else={<Footer {...footerData} />}>
        <FooterMobile {...footerData} />
      </IfElse>
      <IfElse if={!disabledAppGuideBanner && !!isMobile && !isLanding && !inApp}>
        <AppGuideBanner />
      </IfElse>
      {scroll?.top === 0 && <CookieBanner />}
    </div>
  );
};

export function getNoLayout(page: ReactElement) {
  return <>{page}</>;
}

export default NewLayout;
