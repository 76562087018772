import React, { PropsWithChildren } from "react";
import styles from "./index.module.scss";

const newMain = ({ children }: PropsWithChildren) => {
  return (
    <main className={styles.main} id={"international-main"}>
      {children}
    </main>
  );
};

export default newMain;
