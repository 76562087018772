import React from "react";
import classNames from "classnames";
import Link from "next/link";
import { useTranslation } from "hooks";
import { CusIcon, OutLink } from "components";
import { FooterProps } from "../types";
import { MenuItemProps } from "types/language";
import { INTERNATIONAL_LOGO_FOOTER_PC } from "constants/images";
import { XTImage } from "components/XTImage";
import styles from "./index.module.scss";

const Footer = ({ menus, policies, copyright, email, links, className }: FooterProps & { className?: string }) => {
  const { t } = useTranslation();

  const node = (i: MenuItemProps) => (
    <OutLink href={i.value} className={styles.text} rel={i.rel} key={i.label} onClick={i.onClick} disabledLanding={i.disabledLanding} disabledLocaleAdd={i.disabledLocaleAdd} target={i.target}>
      {i.label}
    </OutLink>
  );

  const _renderRouteLinks = (menu: MenuItemProps, index: number) => {
    return (
      <ul className={classNames(styles.menuItem)} key={index}>
        <li className={classNames(styles.title)} key={menu.label}>
          {menu.label}
        </li>
        {menu.children?.map((i) => (
          <li className={styles.item} key={i.label}>
            {node(i)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <footer className={classNames(styles.footer, className)} id={"international-footer"}>
      <section className={classNames("xt-safe-row", styles.navs)}>
        <div className={styles.menus}>{menus.map(_renderRouteLinks)}</div>
        <div className={styles.contact}>
          <div className={styles.email}>{email}</div>
          <div className={styles.social}>
            <div className={styles.title}>{t("common-followUs")}</div>
            <ul className={styles.socialMedias}>
              {links.map((link, index) => {
                const { title, href, icon } = link;
                return (
                  <li key={index} className={styles.socialLink}>
                    <Link href={href} title={title} target={"_blank"} rel={"noopener noreferrer nofollow"}>
                      <CusIcon type={icon} />
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={styles.downloadGuide}>
            <div className={styles.appWrapper}>
              <XTImage src="https://static.xtransfer.com/boss/static/downloadapp_00dc4d4be96a777b.png" alt="xTransfer app" />
              <span className={styles.downloadDesc}>
                Download
                <br />
                XTransfer App
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className={classNames("xt-safe-row", styles.policy)}>
        <XTImage alt="xtransfer" src={INTERNATIONAL_LOGO_FOOTER_PC} className={styles.logo} />
        <ul className={styles.policiesWrapper}>
          {policies.map((policy, index) => {
            return (
              <li key={index} className={styles.policyItem}>
                {node(policy)}
              </li>
            );
          })}
        </ul>
      </section>
      <section className={classNames("xt-safe-row", styles.copyrightWrapper)}>
        <div className={styles.divider} />
        <div className={styles.copyright}>{copyright}</div>
        <div
          className={styles.annotation}
        >{`"China's No.1 B2B Cross-Border Trade Payment Platform" is based on the "Research report on financial service demand of China's SME foreign trade enterprises in 2023" released by iiMedia Research in July 2023`}</div>
      </section>
    </footer>
  );
};

export default Footer;
