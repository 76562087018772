import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Link from "next/link";
import { getPathWithQuery } from "utils/route";
import { useRouter } from "next/router";
import { useCommonState } from "context";
import { MenuItemProps } from "types/language";
import XTLogo from "components/XTLogo";
import ContactUsButton from "components/CusButton";
import LoginMenuH5 from "components/LoginMenuH5";
import NewLanguageDrawer from "components/NewLanguageDrawer";
import { HeaderProps } from "../types";
import { isBrowser } from "utils";
import styles from "./index.module.scss";
import IconMenu from "components/SvgComponents/menu.svg";
import IconClose from "components/SvgComponents/close.svg";
import IconArrowDown from "components/SvgComponents/arrow-down.svg";
import IconArrowUp from "components/SvgComponents/arrow-up.svg";

interface NavProps {
  expanded?: boolean;
  onChange: () => void;
  beforeClick?: () => void;
  children?: React.ReactNode;
}

const Nav = ({ expanded, onChange, beforeClick }: NavProps) => {
  return (
    <div className={styles.nav}>
      <XTLogo className={styles.logo} mobile onClick={beforeClick} />
      <span onClick={onChange}>{expanded ? <IconClose className={styles.closeIcon} /> : <IconMenu className={styles.closeIcon} />}</span>
    </div>
  );
};

const MobileHeader = ({ menus, className, children }: HeaderProps & { className?: string }) => {
  const { isLanding, isGlobal } = useCommonState();
  const [visible, setVisible] = useState(false);
  const preOverflow = useRef(isBrowser ? window?.document.body.style["overflow"] : "initial");
  const { asPath } = useRouter();
  const [currentActiveIndex, setCurrentActiveIndex] = useState<number | null>(null);

  useEffect(() => {
    let bodyStyle = document.body.style;
    bodyStyle["overflow"] = visible ? "hidden" : preOverflow.current;
  }, [visible]);

  const handleChange = () => {
    setVisible(!visible);
  };

  const handleLinkClick = () => {
    setVisible(false);
  };

  const renderRouteLinks = (menu: MenuItemProps, index: number) => {
    const isActive = currentActiveIndex === index;

    return (
      <ul className={classNames(styles.menuItem)} key={index}>
        <li
          className={classNames(styles.title)}
          key={menu.label}
          onClick={() => {
            if (isActive) {
              setCurrentActiveIndex(null);
              return;
            }
            setCurrentActiveIndex(index);
          }}
        >
          <span>{menu.label}</span>
          <span className={styles.iconWrapper}>
            {isActive ? <IconArrowUp className={styles.arrowIcon} /> : <IconArrowDown className={styles.arrowIcon} />}
          </span>
        </li>
        <ul className={classNames(styles.subItems, isActive && styles.itemSelected)}>
          {menu.children?.map((i) => (
            <li className={classNames(styles.item)} key={i.label} onClick={handleLinkClick}>
              <Link style={{ width: "100%" }} href={getPathWithQuery({ asPath, toPath: i.value || "" })}>
                {i.label}
              </Link>
            </li>
          ))}
        </ul>
      </ul>
    );
  };

  return (
    <header className={classNames(styles.header, styles.root, className)} id={"international-mobileHeader"}>
      {children}
      <div className={styles.inner}>
        <Nav onChange={handleChange} expanded={visible} />
        <div className={classNames(styles.content, { [`${styles.visible}`]: visible })}>
          <Nav onChange={handleChange} expanded={visible} beforeClick={handleLinkClick} />
          <section className={styles.menuRoot}>
            <div className={styles.menus}>{menus.map(renderRouteLinks)}</div>
            {!isLanding && <NewLanguageDrawer beforeClick={handleLinkClick} />}
          </section>
          <section className={styles.footer}>
            {!isLanding && isGlobal ? (
              <LoginMenuH5 className={styles.linkButton} />
            ) : (
              <ContactUsButton className={styles.linkButton} showIcon={false} beforeClick={handleLinkClick} />
            )}
          </section>
        </div>
      </div>
    </header>
  );
};

export default MobileHeader;
